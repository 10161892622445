import { StateCreator } from "zustand";
import produce from "immer";
import { fetchTokensInfo, getEthGasFee } from "../../pages/api/externalApi";
import { HIGHEST_ETH_GAS_PRICE } from "../../../appConstants";
import { CoinPretty, Dec, DecUtils } from "@keplr-wallet/unit";
import { pstakeInfo } from "../../helpers/config";
import { toPrettyCoin } from "../../helpers/coin";
import {
  getChainFee,
  gravityChain,
  persistenceChain,
} from "../../helpers/utils";
import { useAppStore } from "../store";

export type transactionNames =
  | "approve"
  | "cosmosTransfer"
  | "ethTransfer"
  | "gravityToPersistence"
  | "gravityToEthereum"
  | null;

export type BridgeFeeType = "standard" | "premium";
export type GravityTxnOption = "persistence" | "ethereum";

export type CosmosToEthTxnSteps = 0 | 1 | 2 | -1;

export type BridgeFee = {
  type: BridgeFeeType;
  fee: CoinPretty;
};

export interface TransactionSlice {
  cosmosToEthTxnInfo: {
    modal: boolean;
    stepNumber: CosmosToEthTxnSteps;
  };
  ethToCosmosTxnInfo: {
    modal: boolean;
  };
  setEthToCosmosTxnModal: (value: boolean) => void;
  setCosmosToEthTxnModal: (value: boolean) => void;
  transactionInfo: {
    inProgress: boolean;
    name: transactionNames | null;
    failed?: boolean;
  };
  gravityTxnFee: {
    bridgeFee: CoinPretty;
    chainFee: CoinPretty;
    selectedBridgeFee: BridgeFee;
  };
  gravityTxn: {
    modal: boolean;
    gravityTxnType: GravityTxnOption;
  };
  tokenPrices: {
    ethPrice: 0;
    pstakePrice: 0;
  };
  setCosmosToEthTxnStep: (value: CosmosToEthTxnSteps) => void;
  setBridgeFeeType: (value: BridgeFee) => void;
  setCosmosToEthChainFee: (value: Dec) => void;
  setTxnInfo: (
    inProgress: boolean,
    name: transactionNames,
    failed?: boolean
  ) => void;
  setCosmosToEthBridgeFee: () => void;
  setGravityTxnType: (value: GravityTxnOption) => void;
  setGravityTxnModal: (value: boolean) => void;
}

export const createTransactionSlice: StateCreator<TransactionSlice> = (
  set,
  get
) => ({
  cosmosToEthTxnInfo: {
    modal: false,
    stepNumber: 1,
  },
  ethToCosmosTxnInfo: {
    modal: false,
  },
  tokenPrices: {
    ethPrice: 0,
    pstakePrice: 0,
  },
  gravityTxn: {
    gravityTxnType: "persistence",
    modal: false,
  },
  gravityTxnFee: {
    bridgeFee: toPrettyCoin("0", pstakeInfo.baseDenom, gravityChain.chainId)
      .trim(true)
      .hideDenom(true),
    chainFee: toPrettyCoin("0", pstakeInfo.baseDenom, gravityChain.chainId)
      .trim(true)
      .hideDenom(true),
    selectedBridgeFee: {
      type: "standard",
      fee: toPrettyCoin("0", pstakeInfo.baseDenom, gravityChain.chainId)
        .trim(true)
        .hideDenom(true),
    },
  },
  transactionInfo: {
    inProgress: false,
    name: null,
    failed: false,
  },
  setEthToCosmosTxnModal: (value: boolean) =>
    set(
      produce((state: TransactionSlice) => {
        state.ethToCosmosTxnInfo.modal = value;
      })
    ),
  setCosmosToEthTxnModal: (value: boolean) =>
    set(
      produce((state: TransactionSlice) => {
        state.cosmosToEthTxnInfo.modal = value;
      })
    ),
  setCosmosToEthTxnStep: (value: CosmosToEthTxnSteps) =>
    set(
      produce((state: TransactionSlice) => {
        state.cosmosToEthTxnInfo.stepNumber = value;
      })
    ),
  setTxnInfo: (inProgress: boolean, name: transactionNames, failed?) =>
    set((state) => ({ transactionInfo: { inProgress, name, failed } })),
  setBridgeFeeType: (value: BridgeFee) =>
    set(
      produce((state: TransactionSlice) => {
        state.gravityTxnFee.selectedBridgeFee = value;
      })
    ),
  setCosmosToEthChainFee: (value: Dec) => {
    set(
      produce((state: TransactionSlice) => {
        try {
          state.gravityTxnFee.chainFee = getChainFee(value);
        } catch (e) {
          useAppStore.getState().setApiError(true);
        }
      })
    );
  },
  setCosmosToEthBridgeFee: async () => {
    try {
      const gasFee = await getEthGasFee();
      const tokePrices = await fetchTokensInfo();

      const bridgeFee = toPrettyCoin(
        new Dec(HIGHEST_ETH_GAS_PRICE)
          .mul(new Dec(gasFee))
          .mul(new Dec(tokePrices.ethPrice))
          .mul(new Dec(0.000000001))
          .quo(new Dec(tokePrices.pstakePrice))
          .mul(DecUtils.getTenExponentNInPrecisionRange(18)),
        pstakeInfo.coinMinimalDenom,
        persistenceChain.chainId
      )
        .trim(true)
        .hideDenom(true);

      const test = toPrettyCoin(
        new Dec("1").mul(DecUtils.getTenExponentNInPrecisionRange(18)),
        pstakeInfo.baseDenom,
        gravityChain.chainId
      )
        .trim(true)
        .hideDenom(true);
      set(
        produce((state: TransactionSlice) => {
          state.gravityTxnFee.bridgeFee = bridgeFee;
        })
      );
      set(
        produce((state: TransactionSlice) => {
          state.tokenPrices = tokePrices;
        })
      );
    } catch (e) {
      useAppStore.getState().setApiError(true);
    }
  },
  setGravityTxnType: (value: GravityTxnOption) =>
    set(
      produce((state: TransactionSlice) => {
        state.gravityTxn.gravityTxnType = value;
      })
    ),
  setGravityTxnModal: (value: boolean) =>
    set(
      produce((state: TransactionSlice) => {
        state.gravityTxn.modal = value;
      })
    ),
});
