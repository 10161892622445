import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
import { coin } from "@cosmjs/amino";
import Long from "long";
import { GRAVITY_SEND_URL, IBC_TRANSFER_URL } from "../../appConstants";
import { MsgSendToEth } from "./proto/gravity/v1/gravity/v1/msgs";

export interface TransferMsgTypes {
  typeUrl?: string;
  value?: MsgTransfer;
}

export const TransferMsg = (
  channel: string,
  fromAddress: string,
  toAddress: string,
  amount: string,
  timeoutHeight: any,
  timeoutTimestamp: string | number | Long.Long | undefined,
  denom: string,
  port = "transfer"
): TransferMsgTypes => {
  return {
    typeUrl: IBC_TRANSFER_URL,
    value: MsgTransfer.fromPartial({
      sourcePort: port,
      sourceChannel: channel,
      token: coin(amount, denom),
      sender: fromAddress,
      receiver: toAddress,
      timeoutHeight: {
        revisionNumber: timeoutHeight?.revisionNumber,
        revisionHeight: timeoutHeight?.revisionHeight,
      },
      timeoutTimestamp: timeoutTimestamp,
    }),
  };
};

export function SendMsg(
  fromAddress: string,
  depositeAddress: string,
  amount: string,
  denom: string,
  bridgeFee: string,
  chainFee: string
) {
  return {
    typeUrl: GRAVITY_SEND_URL,
    value: MsgSendToEth.fromPartial({
      sender: fromAddress,
      ethDest: depositeAddress,
      amount: coin(amount, denom),
      bridgeFee: coin(bridgeFee, denom),
      chainFee: coin(chainFee, denom),
    }),
  };
}
