import Axios from "axios";

export const ethGasEstimateUrl =
  "https://api.persistence.one/etherscan/gasEstimate";

export const fetchTokensInfo = async (): Promise<any> => {
  try {
    const [ethereum, pstake] = await Promise.all([
      Axios.get(`https://api.coingecko.com/api/v3/coins/ethereum`),
      Axios.get(`https://api.coingecko.com/api/v3/coins/pstake-finance`),
    ]);

    if (ethereum && ethereum.data && pstake && pstake.data) {
      const ethPrice: number = ethereum.data.market_data.current_price.usd;
      const pstakePrice: number = pstake.data.market_data.current_price.usd;
      return {
        ethPrice: Number(ethPrice),
        pstakePrice: Number(pstakePrice),
      };
    }
    return {
      ethPrice: 0,
      pstakePrice: 0,
    };
  } catch (e) {
    console.log(e, "error in fetch tokens");
    return {
      ethPrice: 0,
      pstakePrice: 0,
    };
  }
};

export const getEthGasFee = async (): Promise<number> => {
  try {
    const res = await Axios.get(ethGasEstimateUrl);
    if (res && res.data) {
      return Number(res.data.result.SafeGasPrice);
    }
    return 0;
  } catch (e) {
    console.log(e, "error in fetch tokens");
    return 0;
  }
};
