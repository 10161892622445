import { StateCreator } from "zustand";
import { Gravity, Pstake, StkEth } from "../../contracts/types";
import { handleMetamaskConnection } from "../../helpers/wallets";
import { OfflineDirectSigner } from "@cosmjs/proto-signing";
import { OfflineSigner } from "@cosmjs/launchpad";

export type FlowType = "normal" | "reverse";

export interface Instances {
  pStakeInstance: Pstake;
  gravityInstance: Gravity;
  stkEthInstance: StkEth;
}

export interface MetaMaskInfo {
  instances: Instances | null;
  walletConnected: boolean;
  address: string;
}

export interface KeplrInfo {
  persistenceAddress: string;
  gravityAddress: string;
  walletConnected: boolean;
  persistenceSigner: OfflineSigner | OfflineDirectSigner | null;
  gravitySigner: OfflineSigner | OfflineDirectSigner | null;
}

export type WalletNames = "Metamask";

export interface WalletSlice {
  apiError: boolean;
  flowType: FlowType;
  metaMaskInfo: MetaMaskInfo;
  keplrInfo: KeplrInfo;
  connectMetaMask: (contract: any) => void;
  updateFlowType: () => void;
  connectKeplr: (info: KeplrInfo) => void;
  setApiError: (info: boolean) => void;
}

export const createWalletSlice: StateCreator<WalletSlice> = (set, get) => ({
  metaMaskInfo: {
    instances: null,
    walletConnected: false,
    address: "",
  },
  apiError: false,
  flowType: "normal",
  keplrInfo: {
    walletConnected: false,
    persistenceAddress: "",
    gravityAddress: "",
    gravitySigner: null,
    persistenceSigner: null,
  },
  updateFlowType: async () => {
    const value: FlowType = get().flowType === "normal" ? "reverse" : "normal";
    set({
      flowType: value,
    });
  },
  setApiError: async (value: boolean) => {
    set({
      apiError: value,
    });
  },
  connectMetaMask: async (contract: any) => {
    const response: MetaMaskInfo = await handleMetamaskConnection(contract);
    set({
      metaMaskInfo: response,
    });
  },
  connectKeplr: async (info: KeplrInfo) => {
    set({
      keplrInfo: info,
    });
  },
});
