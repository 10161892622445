import _ from "lodash";
import {
  contracts,
  cosmosChains,
  ibcConfiguration,
  pollingConfiguration,
  pstakeInfo,
} from "./config";
import { JsonRpcSigner } from "@ethersproject/providers";
import { Instances, WalletNames } from "../store/slices/walletSlice";
import * as Sentry from "@sentry/nextjs";
import { Scope } from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types/types/scope";
import { Primitive } from "@sentry/types";
import { displayToast } from "../components/molecules/toast";
import { ToastType } from "../components/molecules/toast/types";
import { useAppStore } from "../store/store";
import {
  Gravity,
  Gravity__factory,
  Pstake,
  Pstake__factory,
  StkEth,
  StkEth__factory,
} from "../contracts/types";
import { BigNumberish, utils } from "ethers";
import { Tendermint34Client } from "@cosmjs/tendermint-rpc";
import {
  Coin,
  createProtobufRpcClient,
  QueryClient,
  setupIbcExtension,
} from "@cosmjs/stargate";
import {
  QueryAllBalancesResponse,
  QueryClientImpl as BankQuery,
} from "cosmjs-types/cosmos/bank/v1beta1/query";
import { Decimal } from "@cosmjs/math";
import {
  GRAVITY_BRIDGE,
  GRAVITY_CHAIN_FEE_CONSTANT,
  MIN_CHAIN_FEE_BASIS_POINTS,
  PERSISTENCE,
} from "../../appConstants";
import { QueryChannelClientStateResponse } from "cosmjs-types/ibc/core/channel/v1/query";
import Long from "long";
import { TransferMsg } from "./protoMsg";
import { CoinPretty, Dec, DecUtils } from "@keplr-wallet/unit";
import { toPrettyCoin } from "./coin";
import { ChainInfo } from "@keplr-wallet/types";
import { Window as KeplrWindow } from "@keplr-wallet/types/build/window";

const tendermintRPC = require("@cosmjs/tendermint-rpc");
const tendermint = require("cosmjs-types/ibc/lightclients/tendermint/v1/tendermint");

declare global {
  interface Window extends KeplrWindow {}
}

export const persistenceChain = cosmosChains.find(
  (chain) => chain.chainName === PERSISTENCE
);
export const gravityChain = cosmosChains.find(
  (chain) => chain.chainName === GRAVITY_BRIDGE
);

export const emptyFunc = () => ({});

export const removeCommas = (str: any) =>
  _.replace(str, new RegExp(",", "g"), "");

const reverseString = (str: any) =>
  removeCommas(_.toString(_.reverse(_.toArray(str))));

const recursiveReverse = (input: any): string => {
  if (_.isArray(input))
    return _.toString(_.reverse(_.map(input, (v: any) => recursiveReverse(v))));
  if (_.isString()) return reverseString(input);
  return reverseString(`${input}`);
};

export const sixDigitsNumber = (value: string, length = 6): string => {
  let inputValue = value.toString();
  if (inputValue.length >= length) {
    return inputValue.substring(0, length);
  } else {
    const stringLength = length - inputValue.length;
    let newString = inputValue;
    for (let i = 0; i < stringLength; i++) {
      newString += "0";
    }
    return newString;
  }
};

export const formatNumber = (v = 0, size = 3, decimalLength = 6): string => {
  let str = `${v}`;
  if (!str) return "NaN";
  let substr = str.split(".");
  if (substr[1] === undefined) {
    let newString = "0";
    for (let i = 1; i < decimalLength; i++) {
      newString += "0";
    }
    substr.push(newString);
  } else {
    substr[1] = sixDigitsNumber(substr[1], decimalLength);
  }
  str = reverseString(substr[0]);
  const regex = `.{1,${size}}`;
  const arr = str.match(new RegExp(regex, "g"));
  return `${recursiveReverse(arr)}${substr[1] ? `.${substr[1]}` : ""}`;
};

export const stringTruncate = (str: string, length = 7): string => {
  if (str.length > 30) {
    return (
      str.substring(0, length) +
      "..." +
      str.substring(str.length - length, str.length)
    );
  }
  return str;
};

export const truncateToFixedDecimalPlaces = (
  num: number | string,
  decimalPlaces = 6
): number => {
  let formatted: number | string;
  if (num.toString().includes(",")) {
    formatted = num.toString().replace(/,/g, "");
  } else {
    formatted = num;
  }
  const regexString = "^-?\\d+(?:\\.\\d{0,dp})?";
  const regexToMatch = regexString.replace("dp", `${decimalPlaces}`);
  const regex = new RegExp(regexToMatch);
  const matched = formatted.toString().match(regex);
  if (matched) {
    return parseFloat(matched[0]);
  }
  return 0;
};

export const decimalize = (valueString: string | number, decimals = 6) => {
  let truncate: string;
  if (typeof valueString === "number") {
    truncate = valueString.toString();
  } else {
    truncate = valueString;
  }
  let finalString: string;
  if (truncate.includes(".")) {
    finalString = truncate.split(".")[0];
  } else {
    finalString = truncate;
  }
  return Decimal.fromAtomics(finalString, decimals).toString();
};

export const unDecimalize = (
  valueString: string | number,
  decimals = 6,
  truncateDecimals = false
) => {
  console.log(valueString, "unDecimalize");
  const decimalString = Decimal.fromUserInput(
    valueString.toString(),
    decimals
  ).atomics;
  if (truncateDecimals) {
    let finalString: string;
    if (decimalString.includes(".")) {
      finalString = decimalString.split(".")[0];
    } else {
      finalString = decimalString;
    }
    return finalString;
  } else {
    return decimalString;
  }
};

export const getChain = (chainId: string) => {
  return cosmosChains.find((chain) => chain.chainId === chainId);
};

export const getWalletProvider = (wallet: WalletNames): any => {
  let provider: any;
  switch (wallet) {
    case "Metamask":
      provider = window.ethereum;
      break;
    default:
      provider = window.ethereum;
      break;
  }
  return provider;
};

export const exceptionHandle = (
  e: any,
  sentryTag: { [key: string]: Primitive }
) => {
  displayToast(
    {
      message: "This transaction could not be completed",
    },
    ToastType.ERROR
  );
  useAppStore.getState().setTxnInfo(false, null, true);
  const customScope = new Scope();
  customScope.setLevel("fatal");
  customScope.setTags(sentryTag);
  sentryReport(e, customScope);
};

export const sentryReport = (exception: any, context: CaptureContext) => {
  console.log(exception);
  Sentry.captureException(exception, context);
};

export const fetchInstance = (signer: JsonRpcSigner): Instances => {
  const pstakeContractAddress =
    contracts[process.env.NEXT_PUBLIC_ENVIRONMENT!]["pstake"];
  const gravityContractAddress =
    contracts[process.env.NEXT_PUBLIC_ENVIRONMENT!]["gravity"];

  const pStakeContract: Pstake = Pstake__factory.connect(
    pstakeContractAddress,
    signer
  );
  const gravityContract: Gravity = Gravity__factory.connect(
    gravityContractAddress,
    signer
  );
  const stkEthContract: StkEth = StkEth__factory.connect(
    gravityContractAddress,
    signer
  );

  return {
    pStakeInstance: pStakeContract,
    gravityInstance: gravityContract,
    stkEthInstance: stkEthContract,
  };
};

export const getTokenBalance = (
  balances: QueryAllBalancesResponse,
  tokenDenom: string
) => {
  if (balances && balances?.balances?.length) {
    const token: Coin | undefined = balances.balances.find(
      (item: Coin) => item.denom === tokenDenom
    );
    if (token === undefined) {
      return "0";
    } else {
      return token!.amount;
    }
  } else {
    return "0";
  }
};

export const keplrWallet = async (chainId: string = "core-1"): Promise<any> => {
  if (!window.getOfflineSigner || !window.keplr) {
    throw new Error("install keplr extension");
  }
  if (chainId === "gravity-bridge-3") {
    window.keplr.defaultOptions = {
      sign: {
        preferNoSetFee: !0,
      },
    };
  } else {
    window.keplr.defaultOptions = {
      sign: {
        preferNoSetFee: false,
      },
    };
  }
  await window.keplr.enable(chainId);
  const offlineSigner = await window.getOfflineSignerAuto!(chainId);
  const accounts = await offlineSigner.getAccounts();
  return {
    address: accounts[0].address,
    signer: offlineSigner,
  };
};

export const getMetamaskBalances = async (
  instances: Pstake,
  address: string
) => {
  try {
    const balance: BigNumberish = await instances.balanceOf(address);
    return utils.formatEther(balance);
  } catch (e) {
    exceptionHandle(e, { "Error while fetching metamask balance": "" });
    return "0";
  }
};

export async function RpcClient(rpc: string) {
  const tendermintClient = await Tendermint34Client.connect(rpc);
  const queryClient = new QueryClient(tendermintClient);
  return createProtobufRpcClient(queryClient);
}

export const getAllowances = async (instances: Pstake, address: string) => {
  try {
    const gravityContractAddress =
      contracts[process.env.NEXT_PUBLIC_ENVIRONMENT!]["gravity"];
    const allowance: BigNumberish = await instances.allowance(
      address,
      gravityContractAddress
    );
    return allowance;
  } catch (e: any) {
    exceptionHandle(e, { "Error while fetching keplr balance": "" });
    return utils.parseEther("0");
  }
};

export const decodeTendermintClientStateAny = (clientState: any) => {
  if (
    (clientState === null || clientState === void 0
      ? void 0
      : clientState.typeUrl) !== "/ibc.lightclients.tendermint.v1.ClientState"
  ) {
    throw new Error(
      `Unexpected client state type: ${
        clientState === null || clientState === void 0
          ? void 0
          : clientState.typeUrl
      }`
    );
  }
  return tendermint.ClientState.decode(clientState.value);
};

// copied from node_modules/@cosmjs/stargate/build/queries/ibc.js
export const decodeTendermintConsensusStateAny = (consensusState: any) => {
  if (
    (consensusState === null || consensusState === void 0
      ? void 0
      : consensusState.typeUrl) !==
    "/ibc.lightclients.tendermint.v1.ConsensusState"
  ) {
    throw new Error(
      `Unexpected client state type: ${
        consensusState === null || consensusState === void 0
          ? void 0
          : consensusState.typeUrl
      }`
    );
  }
  return tendermint.ConsensusState.decode(consensusState.value);
};

export async function MakeIBCTransferMsg({
  channel,
  fromAddress,
  toAddress,
  amount,
  timeoutHeight,
  timeoutTimestamp = ibcConfiguration.timeoutTimestamp,
  denom,
  sourceRPCUrl,
  destinationRPCUrl,
  port = "transfer",
}: any) {
  const tendermintClient = await tendermintRPC.Tendermint34Client.connect(
    sourceRPCUrl
  );
  const queryClient = new QueryClient(tendermintClient);

  const ibcExtension = setupIbcExtension(queryClient);

  return await ibcExtension.ibc.channel
    .clientState(port, channel)
    .then(async (clientStateResponse: QueryChannelClientStateResponse) => {
      const clientStateResponseDecoded = decodeTendermintClientStateAny(
        clientStateResponse?.identifiedClientState?.clientState
      );
      timeoutHeight = {
        revisionHeight:
          clientStateResponseDecoded.latestHeight.revisionHeight.add(
            ibcConfiguration.ibcRevisionHeightIncrement
          ),
        revisionNumber: clientStateResponseDecoded.latestHeight.revisionNumber,
      };
      if (destinationRPCUrl === undefined) {
        const consensusStateResponse =
          await ibcExtension.ibc.channel.consensusState(
            port,
            channel,
            clientStateResponseDecoded.latestHeight.revisionNumber.toInt(),
            clientStateResponseDecoded.latestHeight.revisionHeight.toInt()
          );
        const consensusStateResponseDecoded = decodeTendermintConsensusStateAny(
          consensusStateResponse.consensusState
        );
        const timeoutTime = Long.fromNumber(
          consensusStateResponseDecoded.timestamp.seconds.toNumber()
        )
          .add(timeoutTimestamp)
          .multiply(1000000000); //get time in nanoesconds
        return TransferMsg(
          channel,
          fromAddress,
          toAddress,
          amount,
          timeoutHeight,
          timeoutTime,
          denom,
          port
        );
      } else {
        const remoteTendermintClient =
          await tendermintRPC.Tendermint34Client.connect(destinationRPCUrl);
        const latestBlockHeight = (await remoteTendermintClient.status())
          .syncInfo.latestBlockHeight;
        timeoutHeight.revisionHeight = Long.fromNumber(latestBlockHeight).add(
          ibcConfiguration.ibcRemoteHeightIncrement
        );
        const timeoutTime = Long.fromNumber(0);
        return TransferMsg(
          channel,
          fromAddress,
          toAddress,
          amount,
          timeoutHeight,
          timeoutTime,
          denom,
          port
        );
      }
    })
    .catch((error: any) => {
      Sentry.captureException(
        error.response ? error.response.data.message : error.message
      );
      throw error;
    });
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchAccountBalance = async (
  address: string,
  rpc: string
): Promise<QueryAllBalancesResponse | "0"> => {
  try {
    const rpcClient = await RpcClient(rpc);
    const bankQueryService = new BankQuery(rpcClient);
    return await bankQueryService.AllBalances({
      address: address,
    });
  } catch (error) {
    console.log("error in fetchAccountBalance");
    return "0";
  }
};

export const fetchIndividualTokenBalance = async (
  address: string,
  rpc: string,
  denom: string,
  chainId: string
): Promise<CoinPretty> => {
  try {
    const rpcClient = await RpcClient(rpc);
    const bankQueryService = new BankQuery(rpcClient);
    const balance = await bankQueryService.Balance({
      address: address,
      denom,
    });
    if (balance && balance?.balance) {
      return toPrettyCoin(
        balance?.balance?.amount,
        balance?.balance?.denom,
        chainId
      )
        .trim(true)
        .hideDenom(true);
    } else {
      return toPrettyCoin("0", balance?.balance?.denom, chainId)
        .trim(true)
        .hideDenom(true);
    }
  } catch (error) {
    console.log(error, "error");
    return toPrettyCoin("0", denom, chainId).trim(true).hideDenom(true);
  }
};

export async function pollAccountBalance(
  address: string,
  denom: string,
  chain: ChainInfo,
  availableAmount: CoinPretty
) {
  console.log(availableAmount, "availableAmount", availableAmount.toString());
  let initialBalance: CoinPretty;
  if (availableAmount) {
    initialBalance = availableAmount;
  } else {
    const balances: any = await fetchAccountBalance(address, chain.rpc);
    initialBalance = toPrettyCoin(
      getTokenBalance(balances, denom),
      denom,
      chain.chainId
    )
      .trim(true)
      .hideDenom(true);
  }
  console.log(initialBalance, "initialBalance", initialBalance.toString());
  await delay(pollingConfiguration.initialTxHashQueryDelay);
  for (let i = 0; i < pollingConfiguration.numberOfRetries; i++) {
    try {
      const balances: any = await fetchAccountBalance(address, chain.rpc);
      console.log(balances, "balances");
      const token: Coin | undefined = balances.balances.find(
        (item: Coin) => item.denom === denom
      );
      if (token === undefined) {
        return toPrettyCoin("1", denom, chain.chainId)
          .trim(true)
          .hideDenom(true);
      } else {
        const fetchResult = toPrettyCoin(token.amount, denom, chain.chainId)
          .trim(true)
          .hideDenom(true);
        console.log(fetchResult, "fetchResult", fetchResult.toString());
        if (!fetchResult.toDec().equals(initialBalance.toDec())) {
          return fetchResult;
        } else {
          throw Error("Balance unchanged");
        }
      }
    } catch (error: any) {
      console.log(
        "polling balance in " +
          pollingConfiguration.scheduledTxHashQueryDelay +
          ": " +
          i +
          "th time"
      );
      await delay(pollingConfiguration.scheduledTxHashQueryDelay);
    }
  }
  return toPrettyCoin("0", denom, chain.chainId).trim(true).hideDenom(true);
}

export const getChainFee = (value: Dec) => {
  const baseAmount = new Dec(
    MIN_CHAIN_FEE_BASIS_POINTS / GRAVITY_CHAIN_FEE_CONSTANT
  );
  try {
    return toPrettyCoin(
      value.mul(baseAmount).mul(DecUtils.getTenExponentNInPrecisionRange(18)),
      pstakeInfo.coinMinimalDenom,
      persistenceChain.chainId
    )
      .trim(true)
      .hideDenom(true);
  } catch (e) {
    return toPrettyCoin(
      new Dec("0")
        .mul(baseAmount)
        .mul(DecUtils.getTenExponentNInPrecisionRange(18)),
      pstakeInfo.coinMinimalDenom,
      persistenceChain.chainId
    )
      .trim(true)
      .hideDenom(true);
  }
};
