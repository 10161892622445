import { ChainInfo } from "@keplr-wallet/types";

export type Networks = "ethereum" | "optimism";
import { Bech32Address } from "@keplr-wallet/cosmos";

export interface ChainDetails {
  networkID: number;
  networkIdHex: string;
  networkName: string;
  rpcUrl: string;
  explorerUrl: string;
}

export type ChainList = {
  [index: string | "Testnet" | "Mainnet"]: ChainDetails;
};

export const chains: ChainList = {
  Testnet: {
    networkID: 5,
    networkIdHex: "0x5",
    networkName: "Goerli Testnet",
    rpcUrl:
      "https://eth-goerli.g.alchemy.com/v2/xxBSEQUWrcgWH9Uqqy-0SModdapVP7Ef",
    explorerUrl: "https://goerli.etherscan.io",
  },
  Mainnet: {
    networkID: 1,
    networkIdHex: "0x1",
    networkName: "Ethereum",
    rpcUrl:
      "https://eth-mainnet.g.alchemy.com/v2/M-n1pPlyw7XQTqSyYe83UiEUJHM5uaM8",
    explorerUrl: "https://etherscan.io",
  },
};

// provide exact name of all the contract json files. ex:ethContract.json
export type ContractName = "pstake" | "gravity";

export type ContractList = {
  [index: string | "Testnet" | "Mainnet"]: { [key in ContractName]: string };
};

export const contracts: ContractList = {
  Testnet: {
    pstake: "0xfb5c6815ca3ac72ce9f5006869ae67f18bf77006",
    gravity: "0xa4108aA1Ec4967F8b52220a4f7e94A8201F2D906",
  },
  Mainnet: {
    pstake: "0xfb5c6815ca3ac72ce9f5006869ae67f18bf77006",
    gravity: "0xa4108aA1Ec4967F8b52220a4f7e94A8201F2D906",
  },
};

export const gravityChain = {};

export const cosmosChains: ChainInfo[] = [
  {
    rpc: "https://rpc.core.persistence.one/",
    rest: "https://rest.core.persistence.one/",
    chainId: "core-1",
    chainName: "Persistence",
    stakeCurrency: {
      coinDenom: "XPRT",
      coinMinimalDenom: "uxprt",
      coinDecimals: 6,
      coinGeckoId: "persistence",
    },
    bip44: {
      coinType: 118,
    },
    currencies: [
      {
        coinDenom: "XPRT",
        coinMinimalDenom: "uxprt",
        coinDecimals: 6,
        coinGeckoId: "persistence",
      },
      {
        coinDenom: "STKATOM",
        coinMinimalDenom: "stk/uatom",
        coinDecimals: 6,
        coinGeckoId: "persistence",
      },
      {
        coinDenom: "PSTAKE",
        coinMinimalDenom:
          "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444",
        coinDecimals: 18,
        // coinGeckoId: "pstake-finance",
        coinGeckoId: "pool:pstake",
        coinImageUrl: "/tokens/pstake.png",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "XPRT",
        coinMinimalDenom: "uxprt",
        coinDecimals: 6,
        coinGeckoId: "persistence",
      },
    ],
    bech32Config: Bech32Address.defaultBech32Config("persistence"),
  },
  {
    rpc: "https://gravitychain.io:26657",
    rest: "https://gravitychain.io:1317",
    chainId: "gravity-bridge-3",
    chainName: "GravityBridge",
    stakeCurrency: {
      coinDenom: "GRAVITON",
      coinMinimalDenom: "ugraviton",
      coinDecimals: 6,
      coinGeckoId: "persistence",
    },
    currencies: [
      {
        coinDenom: "GRAV",
        coinMinimalDenom: "ugraviton",
        coinDecimals: 6,
        // coinGeckoId: "graviton",
        coinGeckoId: "pool:ugraviton",
        coinImageUrl: "/tokens/grav.svg",
      },
      {
        coinDenom: "PSTAKE",
        coinMinimalDenom: "gravity0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006",
        coinDecimals: 18,
        // coinGeckoId: "pstake-finance",
        coinGeckoId: "pool:pstake",
        coinImageUrl: "/tokens/pstake.png",
      },
    ],
    bip44: {
      coinType: 118,
    },
    feeCurrencies: [
      {
        coinDenom: "GRAVITON",
        coinMinimalDenom: "ugraviton",
        coinDecimals: 6,
        coinGeckoId: "persistence",
      },
    ],
    bech32Config: Bech32Address.defaultBech32Config("gravity"),
  },
];

export const ibcConfiguration = {
  timeoutTimestamp: 1000,
  ibcRevisionHeightIncrement: 1000,
  ibcRemoteHeightIncrement: 150,
  ibcDefaultPort: "transfer",
  ibcChannelID: "channel-24",
  ibcGravityChannelID: "channel-38",
};

export const pollingConfiguration = {
  initialTxHashQueryDelay: 10000,
  scheduledTxHashQueryDelay: 10000,
  numberOfRetries: 60,
};

export const pstakeInfo = {
  coinDenom: "PSTAKE",
  coinMinimalDenom:
    "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444",
  baseDenom: "gravity0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006",
  coinDecimals: 18,
};
