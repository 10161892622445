import { displayToast } from "../components/molecules/toast";
import { ToastType } from "../components/molecules/toast/types";
import { ETH_REQUEST_ACCOUNTS } from "../../appConstants";
import { addNetwork } from "../components/organisms/navigationBar";
import { chains } from "./config";
import { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import { ethers } from "ethers";
import { fetchInstance } from "./utils";
import {
  ExternalProvider,
  JsonRpcFetchFunc,
} from "@ethersproject/providers/src.ts/web3-provider";
import { useAppStore } from "../store/store";
import { Instances, MetaMaskInfo } from "../store/slices/walletSlice";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const handleNetworkCheck = async (provider: any) => {
  const connectWallet = useAppStore.getState().connectMetaMask;
  let networkCheck = false;
  // if metamask not connected to ethereum then switch network will trigger.
  if (!networkCheck) {
    const chain = chains[env];
    if (provider.chainId !== chain.networkIdHex) {
      const addedBSC = await addNetwork(provider, chain);
      if (!addedBSC) {
        displayToast(
          {
            message: "Error while Switching network",
          },
          ToastType.ERROR
        );
        return;
      }
    }
  }
  await connectWallet(provider);
};

export const handleMetamask = async () => {
  if (!window.ethereum || !window.ethereum) {
    displayToast(
      {
        message: "wallet not installed",
      },
      ToastType.ERROR
    );
    return;
  }
  await handleNetworkCheck(window.ethereum);
};

export const handleMetamaskConnection = async (
  contract: ExternalProvider | JsonRpcFetchFunc | any
): Promise<MetaMaskInfo> => {
  try {
    let accounts;
    accounts = await contract.request({ method: ETH_REQUEST_ACCOUNTS });
    if (accounts && accounts.length !== 0) {
      const account = accounts[0];
      const provider: JsonRpcProvider = new ethers.providers.Web3Provider(
        contract
      );
      const signer: JsonRpcSigner = await provider.getSigner();

      const instances: Instances = await fetchInstance(signer);

      return {
        instances: instances,
        address: account,
        walletConnected: true,
      };
    } else {
      throw new Error("No authorized account found");
    }
  } catch (e: any) {
    displayToast(
      {
        message: e.message!,
      },
      ToastType.ERROR
    );
    console.error(e);
    return {
      instances: null,
      address: "",
      walletConnected: false,
    };
  }
};
