import React, { useEffect, useState } from "react";

import { Icon } from "../../atoms/icon";
import Link from "next/link";
import { useWindowSize } from "../../../customHooks/useWindowSize";
import { ETH, TOKEN_BALANCE } from "../../../../appConstants";
import Styles from "./styles.module.css";
import { ChainDetails } from "../../../helpers/config";
import { useAppStore } from "../../../store/store";
import { useRouter } from "next/router";
import GeofenceNotice from "../../molecules/geofence-banner";

// if network not present in metamask, it will add network to metamask.
export const addNetwork = async (provider: any, chain: ChainDetails) => {
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chain.networkIdHex }], // Hexadecimal version of 80001, prefixed with 0x
    });
    return true;
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chain.networkIdHex, // Hexadecimal version of 80001, prefixed with 0x
              chainName: chain.networkName,
              nativeCurrency: {
                name: ETH,
                symbol: ETH,
                decimals: TOKEN_BALANCE,
              },
              rpcUrls: [chain.rpcUrl],
              blockExplorerUrls: [chain.explorerUrl],
              iconUrls: [""],
            },
          ],
        });
        return true;
      } catch (addError) {
        console.log(addError, "addError");
        // Sentry.captureException("Could not add the bsc network");
        return false;
      }
    }
    return false;
  }
};

const NavigationBar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { isMobile } = useWindowSize();
  const router = useRouter();
  const apiError = useAppStore((state) => state.apiError);
  const headerList = [
    {
      name: "persistence.one",
      url: "https://persistence.one/",
    },
  ];

  useEffect(() => {
    if (apiError) {
      router.push("/maintenance");
    }
  }, [apiError]);

  return (
      <div>
        <GeofenceNotice/>
        <nav className="px-8 py-6 rounded">
          <div className="flex flex-wrap justify-between items-center mx-auto">
            <Link href="/" passHref className="flex items-center">
              <div className={"flex items-center"}>
                <img
                    src={"/images/logo.svg"}
                    className="max-w-[260px]"
                    alt={"stakeIcon"}
                />
              </div>
            </Link>
            <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="outline-none inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg
           -md:hidden focus:outline-none"
                aria-controls="navbar-default"
                aria-expanded="false"
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
            >
              <span className="sr-only">Open main menu</span>
              <Icon iconName={"menu"} viewClass={"w-[20px]"} />
            </button>
            <div
                className={`${
                    isNavExpanded ? "block" : "hidden"
                } -md:block w-full -md:w-auto`}
                id="navbar-default"
            >
              <ul className="flex flex-col mt-4 -md:flex-row -md:space-x-8 -md:mt-0">
                {headerList.map((item, index) => (
                    <li className="text-light-high font-medium text-sm" key={index}>
                      <a
                          href={item.url}
                          target={"_blank"}
                          rel="noreferrer"
                          className={`${Styles.navBarLink} block py-2 pr-0 pl-3 text-white`}
                          aria-current="page"
                      >
                        {item.name}
                      </a>
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
  );
};

export default NavigationBar;
