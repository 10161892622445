import { StateCreator } from "zustand";
import { Instances } from "./walletSlice";
import produce from "immer";
import {
  fetchIndividualTokenBalance,
  getAllowances,
  getMetamaskBalances,
  gravityChain,
  persistenceChain,
} from "../../helpers/utils";
import { pstakeInfo } from "../../helpers/config";
import { BigNumber, utils } from "ethers";
import { CoinPretty, Dec } from "@keplr-wallet/unit";
import { toPrettyCoin } from "../../helpers/coin";

export type TokenNames = "pSTAKE" | "stkETH";

export type Wallets = "metaMask" | "keplr";

export type Balances = {
  [key in Wallets]: { balance: string };
};

export interface Token {
  name: TokenNames;
  balance: number | string;
}

export interface InputToken {
  name: TokenNames;
  amount: Dec;
  amountCheck: boolean;
}

export interface BalanceSlice {
  balances: {
    metaMask: {
      balance: string;
    };
    keplr: {
      gravityBalance: CoinPretty;
      pBalance: CoinPretty;
    };
  };
  fetchMetaMaskBalances: (
    instances: Instances,
    address: string
  ) => Promise<any>;
  fetchKeplrBalances: (address: string, gravityAddress: string) => Promise<any>;
  selectedToken: InputToken;
  setSelectedTokenName: (token: TokenNames) => any;
  setSelectedTokenAmount: (amount: Dec) => any;
  setSelectedTokenAmountCheck: (value: boolean) => any;
  checkAllowance: BigNumber;
  fetchAllowances: (instances: Instances, address: string) => void;
}

export const createBalanceSlice: StateCreator<BalanceSlice> = (set, get) => ({
  selectedToken: {
    name: "pSTAKE",
    amount: new Dec("0"),
    amountCheck: false,
  },
  checkAllowance: utils.parseEther("0"),
  balances: {
    metaMask: {
      balance: "0",
    },
    keplr: {
      gravityBalance: toPrettyCoin(
        "0",
        pstakeInfo.baseDenom,
        gravityChain.chainId
      )
        .trim(true)
        .hideDenom(true),
      pBalance: toPrettyCoin(
        "0",
        pstakeInfo.coinMinimalDenom,
        persistenceChain.chainId
      )
        .trim(true)
        .hideDenom(true),
    },
  },
  setSelectedTokenAmountCheck: (value: boolean) => {
    set(
      produce((state: BalanceSlice) => {
        state.selectedToken.amountCheck = value;
      })
    );
  },
  setSelectedTokenName: (token: TokenNames) => {
    set(
      produce((state: BalanceSlice) => {
        state.selectedToken.name = token;
      })
    );
  },
  setSelectedTokenAmount: async (amount: Dec) => {
    set(
      produce((state: BalanceSlice) => {
        state.selectedToken.amount = amount;
      })
    );
  },
  fetchMetaMaskBalances: async (instances: Instances, address: string) => {
    const response = await getMetamaskBalances(
      instances.pStakeInstance,
      address
    );
    set(
      produce((state: BalanceSlice) => {
        state.balances.metaMask.balance = response;
      })
    );
  },
  fetchKeplrBalances: async (address: string, gravityAddress: string) => {
    const gravityTokenBalance: CoinPretty = await fetchIndividualTokenBalance(
      gravityAddress,
      gravityChain!.rpc!,
      pstakeInfo.baseDenom,
      gravityChain.chainId
    );

    const persistenceTokenBalance: CoinPretty =
      await fetchIndividualTokenBalance(
        address,
        persistenceChain!.rpc!,
        pstakeInfo.coinMinimalDenom,
        persistenceChain.chainId
      );

    console.log(
      persistenceTokenBalance,
      "gravityResponse",
      gravityTokenBalance
    );

    set(
      produce((state: BalanceSlice) => {
        state.balances.keplr = {
          pBalance: persistenceTokenBalance,
          gravityBalance: gravityTokenBalance,
        };
      })
    );
  },
  fetchAllowances: async (instances: Instances, address: string) => {
    const response = await getAllowances(instances.pStakeInstance, address);
    set({
      checkAllowance: response!,
    });
  },
});
