import { StateCreator } from "zustand";

export interface SidebarSlice {
  show: boolean;
  handleSidebar: (value: boolean) => void;
}

export const createSidebarSlice: StateCreator<SidebarSlice> = (set, get) => ({
  show: false,
  handleSidebar: async (value: boolean) => {
    set({
      show: value,
    });
  },
});
