import React, { useEffect, useState } from "react";
import { Icon } from "../../atoms/icon";

const countries = [
  "BB",
  "BF",
  "CM",
  "CD",
  "CG",
  "GI",
  "HT",
  "IR",
  "JM",
  "KP",
  "ML",
  "MZ",
  "MM",
  "SN",
  "SS",
  "SY",
  "TZ",
  "UG",
  "YE",
  "UM",
  "US",
  "GB",
  "CU",
  "CA",
];

const GeofenceNotice = () => {
  const workerUrl = "https://worker-geofence.auditdev.workers.dev/";
  const [country, setCountry] = useState("");

  useEffect(() => {
    fetch(workerUrl)
      .then((response) => response.json())
      .then((data) => {
        setCountry(data.country);
      })
      .catch((error) => console.error("Error fetching country:", error));
  }, [workerUrl]);

  return (
    <div
      className={`${
        countries.includes(country) ? "flex" : "hidden"
      } bg-[#831622] px-4 py-2 items-center justify-center`}
    >
      <div className="text-center mr-4 flex items-center justify-center">
        <Icon
          iconName="info-dark"
          viewClass="!w-[1.2rem] !h-[0.8rem] fill-[#fcfcfc]"
        />
        <p className="text-[#fcfcfc] text-xsm font-medium">
          <span className={"font-semibold"}>IMPORTANT NOTICE:</span>&nbsp;
          Please note that from 1 March 2024, applications on the persistence.one domain will no longer be accessible from your location. See more details{" "}
          <a
            className="underline"
            href="https://geofence-notice.pages.dev/?ref=persistence"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </p>
      </div>
      <div
        onClick={() => {
          setCountry("");
        }}
        className="cursor-pointer mr-2 hidden"
      >
        <Icon iconName="close" viewClass="fill-[#DB2438] !w-[12px] !h-[12px]" />
      </div>
    </div>
  );
};

export default GeofenceNotice;
